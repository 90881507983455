import ProductStoreService from '../services/product-store-service';

const productStoreService = new ProductStoreService();

function sortByOften(arr) {
  arr.sort((a, b) => a.often > b.often ? -1 : 1);
}

const initialState = {
  products: {
    loading: false,
    data: {
      "count": 0,
      "next": null,
      "previous": null,
      "results": []
    }
  },

  user_data: {
    'first_name': "",
    'last_name': "",
    'image': "",
    'date_joined': "",
    'phone_number': '',
    'is_anonymous': true,
    'is_superuser': false,
    'is_staff': false,
    'is_authenticated': false,
  },

  product_filter: {
    car_brand: "",
    car_model: "",
    car_modification: "",
    category: "",
    sub_category: "",
    search_text: ""
  },

  car_brands: {
    selected: '',
    loading: false,
    data: {
      "count": 0,
      "next": null,
      "previous": null,
      "often": [],
      "results": []
    }
  },
  car_model: {
    selected: '',
    loading: false,
    data: {
      "count": 0,
      "next": null,
      "previous": null,
      "often": [],
      "results": []
    }
  },

  car_modification: {
    selected: '',
    loading: false,
    data: {
      "count": 0,
      "next": null,
      "previous": null,
      "often": [],
      "results": []
    }
  },

  car_category: {
    selected: '',
    loading: false,
    data: {
      "count": 0,
      "next": null,
      "previous": null,
      "often": [],
      "results": []
    }
  },

  car_sub_category: {
    selected: '',
    loading: false,
    data: {
      "count": 0,
      "next": null,
      "previous": null,
      "often": [],
      "results": []
    }
  },

  cart: {
    loading: false,
    quantity: 0,
    total: 0,
    discount: 0,
    cartItems: []
  },

  product_details: {
    loading: false,
    data: {
      "id": 0,
      "title": "",
      "price": 0,
      "old_price": 0,
      "images": [],
    }
  }

};

const reducer = (state = initialState, action) => {

  switch (action.type) {

    case 'SET_PRODUCT_FILTER':
      let filter_payload = action.payload;
      const newFilter = {...state.product_filter, ...filter_payload};
      return {
        ...state,
        product_filter: {
          ...newFilter
        }
      };

    case 'PRODUCT_DETAIL_LOADED':
      return {
        ...state,
        product_details: {
          ...state.product_details,
          data: {
            ...state.product_details.data,
            ...action.payload
          }
        }
      };

    case 'PRODUCTS_REQUESTED':
      return {
        ...state,
        products: {...initialState.products, loading: true, data: initialState.products.data}
      };

    case 'CATEGORY_LOADED':
      return {
        ...state,
        car_category: {...state.car_category, loading: false, data: action.payload}
      };

    case 'CAR_MODEL_LOADED':
      let res = [...action.payload.results];
      sortByOften(res);
      let often = res.slice(0, 5);
      let data = {...action.payload, often: often};
      return {
        ...state,
        car_model: {...state.car_model, loading: false, data: data}
      };

    case 'CAR_MODIFICATION_LOADED':
      return {
        ...state,
        car_modification: {...state.car_modification, loading: false, data: action.payload}
      };

    case 'CAR_SUBCATEGORY_LOADED':
      return {
        ...state,
        car_sub_category: {...state.car_sub_category, loading: false, data: action.payload}
      };

    case 'USER_LOADED':
      return {
        ...state,
        user_data: action.payload
      };

    case 'PRODUCTS_LOADED':
      return {
        ...state,
        products: {...state.products, loading: false, data: action.payload}
      };

    case 'PRODUCTS_APPEND_LOADED':
      let resultProduct = [...state.products.data.results, ...action.payload.results];
      let resultData = {
        ...state,
        products: {...state.products, loading: false, data: action.payload}
      };

      resultData.products.data.results = resultProduct;

      return resultData;

    case 'CART_REQUESTED':
      return {
        ...state,
        cart: {...initialState.cart, cartItems: initialState.cart.cartItems}
      };

    case 'CART_LOADED':
      const quantity = action.payload.length;
      let total_cart = action.payload.reduce(function (a, b) {
        return a + b.price * b.quantity;
      }, 0);
      return {
        ...state,
        cart: {...state.cart, quantity: quantity, total: total_cart, loading: false, cartItems: action.payload}
      };

    case 'CAR_BRAND_REQUESTED':
      return {
        ...state,
        car_brands: {...initialState.car_brands, loading: true, data: initialState.car_brands.data}
      };

    case 'CAR_BRAND_LOADED':
      let res_brand = [...action.payload.results];
      sortByOften(res_brand);
      let often_brand = res_brand.slice(0, 5);
      let data_brand = {...action.payload, often: often_brand};

      return {
        ...state,
        car_brands: {...state.car_brands, loading: false, data: data_brand}
      };

    case 'CAR_BRAND_SELECTED':
      return {
        ...state,
        car_brands: {...state.car_brands, loading: false, selected: action.payload, data: state.car_brands.data}
      };

    case 'REMOVE_FROM_CART':
      const pId = action.payload;
      productStoreService.removeFromCart(pId);
      const idx = state.cart.cartItems.findIndex((productCart) => productCart.id === pId);

      const beforeCart = state.cart.cartItems.slice(0, idx);
      const afterCart = state.cart.cartItems.slice(idx + 1);

      let total = beforeCart.reduce(function (a, b) {
        return a + b.price * b.quantity;
      }, 0);
      total = total + afterCart.reduce(function (a, b) {
        return a + b.price * b.quantity;
      }, 0);

      return {
        ...state,
        cart: {
          ...state.cart,
          total: total,
          quantity: beforeCart.length + afterCart.length,
          cartItems: [
            ...beforeCart, ...afterCart
          ]
        }
      };

    case 'CART_ITEM_PLUS':
      const productIdPlus = action.payload;
      const idxProductPlus = state.cart.cartItems.findIndex((productCart) => productCart.id === productIdPlus);
      productStoreService.addToCart(productIdPlus, 1);

      const beforeCartPlus = state.cart.cartItems.slice(0, idxProductPlus);
      const afterCartPlus = state.cart.cartItems.slice(idxProductPlus + 1);
      const prodPlus = state.cart.cartItems[idxProductPlus];
      const newProductPlus = {
        ...prodPlus,
        quantity: prodPlus.quantity + 1,
      };


      let totalPlus = state.cart.cartItems.reduce(function (a, b) {
        return a + b.price * b.quantity;
      }, 0);
      totalPlus = totalPlus + newProductPlus.price * 1;

      return {
        ...state,
        cart: {
          ...state.cart,
          quantity: state.cart.quantity,
          total: totalPlus,
          cartItems: [
            ...beforeCartPlus,
            newProductPlus,
            ...afterCartPlus

          ]
        }
      };

    case 'CART_ITEM_MINUS':
      const productIdMinus = action.payload;
      const idxProductMinus = state.cart.cartItems.findIndex((productCart) => productCart.id === productIdMinus);
      let quantity_change = state.cart.cartItems[idxProductMinus].quantity - 1;
      if (quantity_change < 0) {
        quantity_change = 0;
      }
      productStoreService.update_from_cart(productIdMinus, quantity_change);

      const beforeCartMinus = state.cart.cartItems.slice(0, idxProductMinus);
      const afterCartMinus = state.cart.cartItems.slice(idxProductMinus + 1);
      const prodMinus = state.cart.cartItems[idxProductMinus];
      const newProductMinus = {
        ...prodMinus,
        quantity: quantity_change,
      };


      let totalMinus = state.cart.cartItems.reduce(function (a, b) {
        return a + b.price * b.quantity;
      }, 0);

      if (quantity_change !== 0) {
        totalMinus = totalMinus - newProductMinus.price * 1;
      }

      return {
        ...state,
        cart: {
          ...state.cart,
          quantity: state.cart.quantity,
          total: totalMinus,
          cartItems: [
            ...beforeCartMinus,
            newProductMinus,
            ...afterCartMinus

          ]
        }
      };


    case 'PRODUCT_ADDED_TO_CART':
      const productId = action.payload;
      let product;
      if (typeof productId === 'number') {
        product = state.products.data.results.find((product) => productId === parseInt(product.offer_id));
      } else {
        product = productId;
      }

      const idxProduct = state.cart.cartItems.findIndex((productCart) => productCart.id === product.id);

      productStoreService.addToCart(parseInt(product.id), 1);

      if (idxProduct < 0) {
        const newProduct = {
          id: product.id,
          title: product.title,
          image_url: product.image_url,
          quantity: 1,
          discount: 0,
          price: product.price
        };

        let total = state.cart.cartItems.reduce(function (a, b) {
          return a + b.price * b.quantity;
        }, 0);
        total = total + newProduct.price * 1;

        return {
          ...state,
          cart: {
            ...state.cart,
            quantity: state.cart.quantity + 1,
            total: total,
            cartItems: [
              ...state.cart.cartItems,
              newProduct

            ]
          }
        };
      } else {

        const beforeCart = state.cart.cartItems.slice(0, idxProduct);
        const afterCart = state.cart.cartItems.slice(idxProduct + 1);
        const prod = state.cart.cartItems[idxProduct];

        const newProduct = {
          ...prod,
          quantity: prod.quantity + 1,
        };

        let total = state.cart.cartItems.reduce(function (a, b) {
          return a + b.price * b.quantity;
        }, 0);
        total = total + newProduct.price * 1;

        return {
          ...state,
          cart: {
            ...state.cart,
            quantity: state.cart.quantity,
            total: total,
            cartItems: [
              ...beforeCart,
              newProduct,
              ...afterCart

            ]
          }
        };

      }

    default:
      return state;
  }
};

export default reducer;