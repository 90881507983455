function findGetParam(fromUrl, parameterName, default_value=null) {
    var result = null,
        tmp = [];
    fromUrl
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) {
            result = decodeURIComponent(tmp[1]);
          } else if ((default_value !== null) && (result === null)){
            result = default_value
          }
        });
    return result;
}

export default findGetParam;