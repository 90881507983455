export default class ProductStoreService {

  getProducts(product_filter) {
    let url = "?";
    const pf = product_filter;
    if (product_filter !== undefined && product_filter !== null) {
      Object.keys(pf).map(function (key, index) {
        if ((url !== "?") && (pf[key] !== '')) {
          url += "&";
        }
        if (pf[key] !== '') {
          url += key + '=' + pf[key];
        }
      });
    }
    return fetch("/api/v0/product_optima/" + url).then(response => response.json());
  }

  getUserData(product_id) {
    return fetch("/api/v0/user_data/").then(response => response.json());
  }

  getOffers(product_id) {
    return fetch("/api/v0/offer/?product=" + product_id).then(response => response.json());
  }

  getOffer(offer_id) {
    return fetch("/api/v0/offer/?offer=" + offer_id).then(response => response.json());
  }

  addFavorite(offer_id) {
    return fetch("/api/v0/add_favorite/" + offer_id+"/").then(response => response.json());
  }

  getOfferImages(offer_id) {
    return fetch("/api/v0/offer_images/?offer=" + offer_id).then(response => response.json());
  }

  getProductsAppend(url) {
    return fetch(url).then(response => response.json());
  }

  getCarBrand() {
    return fetch("/api/v0/car_hierarchy/?level=0&page_size=300").then(response => response.json());
  }

  getCarModel(car_brand_id) {
    return fetch("/api/v0/car_hierarchy/?level=1&page_size=300&parent_id=" + car_brand_id).then(response => response.json());
  }

  getCarModification(car_model_id) {
    return fetch("/api/v0/car_hierarchy/?level=2&page_size=300&parent_id=" + car_model_id).then(response => response.json());
  }

  getCategory() {
    return fetch("/api/v0/product_hierarchy/?level=0&page_size=300").then(response => response.json());
  }

  getSubCategory(category_id) {
    return fetch("/api/v0/product_hierarchy/?level=1&page_size=300&parent_id=" + category_id).then(response => response.json());
  }

  createOrder(data) {
    return fetch("/api/v0/create_order/",
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(data)
      }
    ).then(response => response.json());
  }

  getCart() {
    return fetch("/api/v0/get_cart/", {
      credentials: "same-origin"
    }).then(response => response.json());
  }

  getOrders() {
    return fetch("/api/v0/view_orders/", {
      credentials: "same-origin"
    }).then(response => response.json());
  }


  getFavorites() {
    return fetch("/api/v0/view_favorites/", {
      credentials: "same-origin"
    }).then(response => response.json());
  }

  addToCart(product_id, quantity) {
    return fetch("/api/v0/add_to_cart/" + product_id + "/" + quantity + "/", {
      credentials: "same-origin"
    }).then(response => response.json());
  }

  update_from_cart(product_id, quantity) {
    return fetch("/api/v0/update_from_cart/" + product_id + "/" + quantity + "/", {
      credentials: "same-origin"
    }).then(response => response.json());
  }

  removeFromCart(product_id) {
    return fetch("/api/v0/remove_from_cart/" + product_id + "/", {
      credentials: "same-origin"
    }).then(response => response.json());
  }

}