const categoryLoaded = (items) => {
  return {
    type: 'CATEGORY_LOADED',
    payload: items
  };
};

const productDetailLoaded = (data) => {
  return {
    type: 'PRODUCT_DETAIL_LOADED',
    payload: data
  }
};

const productsLoaded = (newProducts) => {
  return {
    type: 'PRODUCTS_LOADED',
    payload: newProducts
  };
};


const productsAppendLoaded = (newProducts) => {
  return {
    type: 'PRODUCTS_APPEND_LOADED',
    payload: newProducts
  };
};


const productsRequested = () => {
  return {
    type: 'PRODUCTS_REQUESTED'
  }
};

const carBrandsRequested = () => {
  return {
    type: 'CAR_BRAND_REQUESTED'
  }
};

const carBrandsLoaded = (newCarBrands) => {
  return {
    type: 'CAR_BRAND_LOADED',
    payload: newCarBrands
  };
};

const carBrandSelected = (carBrand) => {
  return {
    type: 'CAR_BRAND_SELECTED',
    payload: carBrand
  };
};

const productAddedToCart = (productId) => {
  return {
    type: 'PRODUCT_ADDED_TO_CART',
    payload: productId
  };
};


const cartItemPlus = (productId) => {
  return {
    type: 'CART_ITEM_PLUS',
    payload: productId
  };
};

const cartItemMinus = (productId) => {
  return {
    type: 'CART_ITEM_MINUS',
    payload: productId
  };
};

const removeFromCart = (productId) => {
  return {
    type: 'REMOVE_FROM_CART',
    payload: productId
  };
};

const cartLoaded = (cart) => {
  return {
    type: 'CART_LOADED',
    payload: cart
  };
};

const cartRequested = () => {
  return {
    type: 'CART_REQUESTED'
  }
};

const setProductFilter = (product_filter) => {
  return {
    type: 'SET_PRODUCT_FILTER',
    payload: product_filter,
  }
};

const carModelLoaded = (newCarModel) => {
  return {
    type: 'CAR_MODEL_LOADED',
    payload: newCarModel
  };
};

const userLoaded = (user) => {
  return {
    type: 'USER_LOADED',
    payload: user
  };
};

const carModificationLoaded = (newCarModification) => {
  return {
    type: 'CAR_MODIFICATION_LOADED',
    payload: newCarModification
  };
};

const carSubcategoryLoaded = (newCarSubcategory) => {
  return {
    type: 'CAR_SUBCATEGORY_LOADED',
    payload: newCarSubcategory
  };
};

export {
  productsLoaded,
  productsRequested,
  carBrandsRequested,
  carBrandsLoaded,
  carBrandSelected,
  productAddedToCart,
  cartRequested,
  cartLoaded,
  removeFromCart,
  cartItemMinus,
  cartItemPlus,
  categoryLoaded,
  productDetailLoaded,
  setProductFilter,
  carModelLoaded,
  carModificationLoaded,
  carSubcategoryLoaded,
  productsAppendLoaded,
  userLoaded,
};