function getThumbnail(url, size) {

  if (url === undefined){
    return '';
  }

  if (url.startsWith('http') === true){
    return url;
  }

  let tmp = [];
  url
    .split("/")
    .forEach(function (item) {
      tmp = [item, ...tmp];
    });

  tmp = tmp.reverse();
  const idx = tmp.length - 1;
  const before = tmp.slice(0, idx);
  const after = tmp.slice(idx);
  const resultArray = [...before, size, ...after];

  let result = '';
  resultArray.forEach(function (item) {
    result = result + '/' + item;
  });
  result = result.replace("//", "/")
  return result;
}

export default getThumbnail;