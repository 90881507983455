import React from 'react';
import ReactDOM from 'react-dom';
import {combineReducers} from 'redux';
import {createBrowserHistory} from 'history';
import {Provider} from 'react-redux'
import {Router} from 'react-router';
import {Route, Switch} from 'react-router';
import ErrorBoundry from './components/error-boundry';
import ProductStoreService from './services/product-store-service';
import {ProductStoreServiceProvider} from "./components/product-store-service-context";
import Cart from './components/cart';
import CartMenuButtton from './components/cart-menu-button';

import store from './store';
import {CartPage, ProductDetailsPage, CheckoutPage, LkSettingsPage, LkPersonalPage} from "./components/pages";
import {ConnectedRouter, connectRouter} from 'connected-react-router';
import {Provider as ProviderMobx} from 'mobx-react';
import {RouterStore, syncHistoryWithStore} from 'mobx-react-router';
import makeInspectable from 'mobx-devtools-mst';
import HomePage from "./components/pages/home-page";
import SearchField from "./components/search-field";
import MenuLogo from "./components/menu-logo";
import LkOrdersPage from "./components/pages/lk-orders-page";
import LkFavoritesPage from "./components/pages/lk-favorites-page";

const productStoreService = new ProductStoreService();


const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const stores = {
  // Key can be whatever you want
  routing: routingStore,
  // ...other stores
};
makeInspectable(stores);

const history = syncHistoryWithStore(browserHistory, routingStore);

ReactDOM.render(
  <Provider store={store}>
    <ProviderMobx {...stores}>
      <ErrorBoundry>
        <ProductStoreServiceProvider value={productStoreService}>
          <Router history={history}>
            <Switch>
              <Route
                path="/catalog/"
                component={HomePage}
                exact/>
              <Route
                path="/catalog/cart/"
                component={CartPage}
                exact/>
              <Route
                path="/catalog/checkout/"
                component={CheckoutPage}
                exact/>
              <Route
                path="/catalog/details/:product_id/:offer_id/"
                component={ProductDetailsPage}
              />
              <Route
                path="/catalog/details/:product_id/"
                component={ProductDetailsPage}
              />
              <Route
                path="/catalog/detail_offer/:offer_id/"
                component={ProductDetailsPage}
              />

              <Route
                path="/lk/orders/"
                component={LkOrdersPage}
              />
              <Route
                path="/lk/favorites/"
                component={LkFavoritesPage}
              />
              <Route
                path="/lk/personal/"
                component={LkPersonalPage}
              />

              <Route
                path="/lk/settings/"
                component={LkSettingsPage}
              />

              <Route
                path="/"
                component={HomePage}
                exact/>
            </Switch>

          </Router>
        </ProductStoreServiceProvider>
      </ErrorBoundry>
    </ProviderMobx>
  </Provider>,
  document.getElementById("s_panel")
);

ReactDOM.render(
  <Provider store={store}>
    <ProviderMobx {...stores}>
      <ErrorBoundry>
        <ProductStoreServiceProvider value={productStoreService}>
          <Router history={history}>
            <Cart cart_type={'side'}/>
          </Router>
        </ProductStoreServiceProvider>
      </ErrorBoundry>
    </ProviderMobx>
  </Provider>,
  document.getElementById("cart-offcanvas")
);

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundry>
      <CartMenuButtton/>
    </ErrorBoundry>
  </Provider>,
  document.getElementById("cart-menu-button")
);


ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundry>
      <Router history={history}>
        <SearchField/>
      </Router>
    </ErrorBoundry>
  </Provider>,
  document.getElementById("search-field")
);

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundry>
      <Router history={history}>
        <MenuLogo/>
      </Router>
    </ErrorBoundry>
  </Provider>,
  document.getElementById("menu-logo")
);
